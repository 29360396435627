import axios from "axios"
import { apiURLV2 } from "./utils"

export const getEditorsPicks = () =>
  axios.get(`${apiURLV2}/courses-editorpicks/?format=json`)

export const getCourses = ({ cat, query }) =>
  axios.get(
    `${apiURLV2}/courses/?format=json${cat ? `&cat=${cat}` : ""}${
      query ? `&q=${query}` : ""
    }`
  )

export const getCourseById = courseId =>
  axios.get(`${apiURLV2}/courses/${courseId}/?format=json`)

export const getCategories = () =>
  axios.get(`${apiURLV2}/course-category/?format=json`)

export const getCategoryById = id =>
  axios.get(`${apiURLV2}/course-category/${id}/?format=json`)

export const getCoursesByCategory = categoryId =>
  axios.get(`${apiURLV2}/courses/?cat=${categoryId}&format=json`)

export const getSearchedCourses = query =>
  axios.get(`${apiURLV2}/courses/?q=${query}&format=json`)

export const getCoursesByTutor = tutorId =>
  axios.get(`${apiURLV2}/courses/?tutor=${tutorId}&format=json`)

export const getCourseReviewsByCourseId = courseId =>
  axios.get(`${apiURLV2}/course-rate/?course=${courseId}&format=json`)

export const getCourseReviewReplyById = replyId =>
  axios.get(`${apiURLV2}/course-rate-reply/${replyId}/`)

export const submitCourseReviewReply = data =>
  axios.post(`${apiURLV2}/course-rate-reply/`, data)

export const updateCourseReviewReply = (id, data) =>
  axios.patch(`${apiURLV2}/course-rate-reply/${id}/`, data)

export const deleteCourseReviewReply = id =>
  axios.delete(`${apiURLV2}/course-rate-reply/${id}`)

export const getOwnedCourses = () =>
  axios.get(`${apiURLV2}/courses/?owned=1&format=json`)

export const getCourseParticipants = courseId =>
  axios.get(`${apiURLV2}/course-participants/${courseId}/?format=json`)

export const getCourseComments = ({ refId, page = 1 }) =>
  axios.get(
    `${apiURLV2}/course-comment/?reference=${refId}&page=${page}&format=json`
  )

export const getCourseCommentById = commentId =>
  axios.get(`${apiURLV2}/course-comment/${commentId}/?format=json`)

export const submitCourseComment = data =>
  axios.post(`${apiURLV2}/course-comment/`, data)

export const deleteCourseComment = commentId =>
  axios.delete(`${apiURLV2}/course-comment/${commentId}`)

export const getCourseCommentReplies = commentId =>
  axios.get(`${apiURLV2}/course-2nd-comment/?comment=${commentId}&format=json`)

export const submitCourseCommentReply = data =>
  axios.post(`${apiURLV2}/course-2nd-comment/`, data)

export const getQuizHistoryByQuizId = quizId =>
  axios.get(`${apiURLV2}/course-quiz-answer/?quiz=${quizId}`)

export const submitQuizAnswer = data =>
  axios.post(`${apiURLV2}/course-quiz-answer/`, data)

export const deleteCourseCommentReply = replyId =>
  axios.delete(`${apiURLV2}/course-2nd-comment/${replyId}`)

export const buyCourse = data => axios.post(`${apiURLV2}/payment/`, data)

export const getCourseCertificate = id =>
  axios.get(`${apiURLV2}/course-certificate/${id}/`)

export const getCourseCertificateByCourseId = courseId =>
  axios.get(`${apiURLV2}/certificate/bycourse/${courseId}/`)

export const createCourseCertificate = data =>
  axios.post(`${apiURLV2}/course-certificate/`, data)

export const getCourseProgress = courseId =>
  axios.get(`${apiURLV2}/my-progress/${courseId}`)

export const getCourseBenefitByCourseId = courseId =>
  axios.get(`${apiURLV2}/course-benefit/?course=${courseId}`)

export const getTutorBySlug = slug => axios.get(`${apiURLV2}/tutor/${slug}/`)

export const submitReview = data => axios.post(`${apiURLV2}/course-rate/`, data)

export const getRatingByCourseAndUser = (courseId, userId) =>
  axios.get(`${apiURLV2}/course-rate/?course=${courseId}&user=${userId}`)

export const submitEditedCourseReview = (rateId, data) =>
  axios.patch(`${apiURLV2}/course-rate/${rateId}/`, data)

export const getCourseKodeToken = (courseId, kodeToken) =>
  axios.get(
    `${apiURLV2}/course-kupon-diskon/?course=${courseId}&check=${kodeToken}`
  )

export const submitCoursePayment = data =>
  axios.post(`${apiURLV2}/payment/`, data)
